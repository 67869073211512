@import "webfonts";
@import "vars";
@import "bootstrap-5.2.2/scss/bootstrap.scss";

body {
  font-family: $font-serif;
  background: linear-gradient(225deg, #16cd9f, #e4a54c, #e42da5, #b815e9);
  background-size: 800% 800%;

  -webkit-animation: bodyGrad 15s ease infinite;
  -moz-animation: bodyGrad 15s ease infinite;
  animation: bodyGrad 15s ease infinite;
}

@-webkit-keyframes bodyGrad {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes bodyGrad {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes bodyGrad {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.random-bg,
.border-box-image {
  background-color: #212529;
  transition: background-color 2s ease;
}

/*
 * Globals
 */

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}

/*
 * Base structure
 */

// .cover-container {
//   max-width: 42em;
// }

a {
  transition: color 0.3s ease;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 2rem;
}

.border-box {
  border-top: 1px solid white;
  &:hover .border-box-image {
    background-color: white !important;
    color: #212529;
  }
  .border-box-image {
    width: 1rem;
    height: 1rem;
    border: 1px solid white;
    position: relative;
    top: -0.5rem;
    border-radius: 1.5rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    &.border-box-icon {
      width: 4rem;
      height: 4rem;
      background: #212529;
      border-radius: 2rem;
      font-size: 2rem;
      top: -2rem;
    }
    .agLetters {
      width: 2rem;
    }
  }
}

.lead {
  margin-block: 1.5rem;
  // position: relative;
  // &::before {
  //   content: open-quote;
  //   position: absolute;
  //   left: -30px;
  //   top: -12px;
  //   font-size: 3rem;
  // }
}

.peep {
  -webkit-animation: slide-in-bottom 5s ease-out 60s both;
  animation: slide-in-bottom 5s ease-out 30s both;
  pointer-events: none;
  img {
    animation: peepAnim 8s ease 1s infinite normal forwards;
    width: 125px;
    height: 125px;
    // background-color: aquamarine;
    bottom: -10px;
    position: relative;
  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes peepAnim {
  0%,
  70%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 0%;
  }

  75% {
    transform: translateX(-20px) rotate(-4deg);
  }

  80% {
    transform: translateX(10px) rotate(4deg);
  }

  85% {
    transform: translateX(-10px) rotate(-3deg);
  }

  90% {
    transform: translateX(5px) rotate(2deg);
  }

  95% {
    transform: translateX(-3px) rotate(-1deg);
  }
}

a {
  color: white;
  &:hover {
    color: #e6d9be;
  }
}

/*
 * Header
 */
header .logo a {
  text-decoration: none;
  color: white;
  // display: inline-flex;
  &.logo-image {
    position: relative;
    width: 200px;
    height: 75px;
    max-width: 100%;
    display: inline-block;
    &:hover .image-hover {
      opacity: 1;
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      object-fit: contain;
      &.image-hover {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        object-fit: contain;
        opacity: 0;
        transition: opacity 0.2s;
      }
    }
  }
}

main, header {
  a:not(.logo-image) {
    color: white;
    background-image: linear-gradient(#f6c09e 100%, #f6c09e 0);
    background-position: 50% 100%, bottom;
    background-repeat: no-repeat;
    background-size: 100% 0;
    border-bottom: 0.2rem inset #f1823d;
    padding: 0.2rem 0.3rem;
    text-decoration: none;
    transform-origin: bottom;
    transition: background-size 0.4s cubic-bezier(0.19, 1, 0.5, 1),
      border-color 0.2s cubic-bezier(0.19, 1, 0.5, 1) 0.05s,
      color 0.2s cubic-bezier(0.19, 1, 0.5, 1) 0.05s;
    &:hover {
      background-size: 100% 100%;
      border-bottom-color: #333;
      color: black;
    }
  }
}

footer {
  font-size: 0.7rem;
}

.nav-masthead {
  font-family: $font-sans;
}

// .nav-masthead .nav-link {
//   color: rgba(255, 255, 255, 1);
//   border-bottom: 0.1rem solid transparent;
// }

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

.getform {
  input,
  textarea {
    display: block;
  }
}
