@font-face {
  font-family: 'Gooper Text';
  src: url("/assets/webfonts/GooperText6-Regular.woff2") format('woff2'), url("assets/webfonts/GooperText6-Regular.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gooper Text';
  src: url("/assets/webfonts/GooperText6-RegularItalic.woff2") format('woff2'), url("assets/webfonts/GooperText6-RegularItalic.woff") format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Gooper Text';
  src: url("/assets/webfonts/GooperText6-Bold.woff2") format('woff2'), url("assets/webfonts/GooperText6-Bold.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gooper Text';
  src: url("/assets/webfonts/GooperText6-BoldItalic.woff2") format('woff2'), url("assets/webfonts/GooperText6-BoldItalic.woff") format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Token Sans';
  src: url("/assets/webfonts/TokenSans7-Light.woff2") format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Token Sans';
  src: url("/assets/webfonts/TokenSans7-Medium.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Token Sans';
  src: url("/assets/webfonts/TokenSans7-Regular.woff2") format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Token Sans';
  src: url("/assets/webfonts/TokenSans7-Bold.woff2") format('woff2');
  font-weight: 700;
  font-style: normal;
}